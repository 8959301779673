import Vue from "vue";
import { ActivitySeller, ContactType } from "@/store/modules/activity-seller.store";
import { Seller } from "@/store/modules/seller.store";
import Component from "vue-class-component";
import { Getter } from "vuex-class";

interface SellerWithRelation extends Seller {
  relation_id: number;
  type: ContactType;
}

@Component
export class HasSellersWithRelation extends Vue {
  @Getter("seller/all") sellers!: Seller[];

  @Getter("activity-seller/all") activitySellers!: ActivitySeller[];

  get sellersWithRelation() {
    return this.activitySellers.map((as) => {
      const seller = this.sellers.find((s) => s.id === as.seller_id);
      return { ...seller, relation_id: as.id, type: as.type };
    }) as SellerWithRelation[];
  }

  get ownersWithRelation() {
    return this.activitySellers
      .filter((as) => as.type === ContactType.owner)
      .map((as) => {
        const seller = this.sellers.find((s) => s.id === as.seller_id);
        return { ...seller, relation_id: as.id };
      }) as SellerWithRelation[];
  }

  getActivitySeller(seller_id: number | undefined) {
    return this.activitySellers.find((activitySeller) => activitySeller.seller_id === seller_id);
  }
}
