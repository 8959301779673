
  import { Component, Mixins } from "vue-property-decorator";
  import { bus } from "@/main";
  import { Getter, Action } from "vuex-class";
  import { Partner, PartnerType } from "@/store/modules/partner.store";
  import { PartnerRequest } from "@/store/modules/partner-request.store";
  import { formatDate } from "@/utils/formatters";
  import type { VastgoedexpertsProduct } from "@/store/modules/vastgoedexperts.store";
  import { HasSellers } from "@/mixins/has-sellers";
  import { HasSellersWithRelation } from "@/mixins/has-sellers-with-relation";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
      AddPartnerServiceModal: () => import("@/components/modals/add-partner-service.vue"),
      AddVgeEpcModal: () => import("@/components/modals/vge/add-epc.vue"),
      AddVgeEkModal: () => import("@/components/modals/vge/add-ek.vue"),
      AddVgeFueltankModal: () => import("@/components/modals/vge/add-fueltank.vue"),
      AddVgeAsbestosModal: () => import("@/components/modals/vge/add-asbestos.vue"),
      AddVgeSignsModal: () => import("@/components/modals/vge/add-signs.vue"),
      UpdateVgeSignsModal: () => import("@/components/modals/vge/update-signs.vue"),
      PartVgeRequestStatus: () => import("@/views/auth/dossier/parts/vge/request-status.vue"),
      PartVgeLogs: () => import("@/views/auth/dossier/parts/vge/logs.vue"),
      PartRequestStatus: () => import("@/views/auth/dossier/parts/partners/request-status.vue"),
    },
  })
  export default class DossierTabPartPartners extends Mixins(HasSellers, HasSellersWithRelation) {
    @Getter("partner/all") partners!: Partner[];
    @Getter("partner-request/all") PartnerRequests!: PartnerRequest[];

    @Action("partner/index") indexPartners!: (payload: any) => Promise<Partner[]>;
    @Action("partner-request/index") indexPartnerRequests!: (payload: any) => Promise<PartnerRequest[]>;

    loading = true;

    bus = bus;

    formatDate = formatDate;

    PartnerType = PartnerType;

    async mounted() {
      let promises = [];

      promises.push(await this.indexPartners({ per_page: 99, q: { and: { "offices.id": this.activity.office_id } } }));

      promises.push(await this.indexPartnerRequests({ per_page: 99, q: { and: { "activity.id": this.activity.id } } }));

      await Promise.all(promises);

      this.loading = false;
    }

    partnerRequests(partner: Partner) {
      return this.PartnerRequests.filter((pr) => pr.partner.id === partner.id);
    }

    startRequest(partner: Partner) {
      switch (partner.type) {
        case PartnerType.vgEEpc:
          bus.$emit("show-vge-add-epc", partner, this.onServiceRequested);
          break;
        case PartnerType.vgEEk:
          bus.$emit("show-vge-add-ek", partner, this.onServiceRequested);
          break;
        case PartnerType.vgEAsbestos:
          bus.$emit("show-vge-add-asbestos", partner, this.onServiceRequested);
          break;
        case PartnerType.vgEFuelOilTank:
          bus.$emit("show-vge-add-fueltank", partner, this.onServiceRequested);
          break;
        case PartnerType.vgEImmoSigns:
          bus.$emit("show-vge-add-signs", partner, this.onServiceRequested);
          break;
        default:
          bus.$emit("show-add-partner-service", partner);
          break;
      }
    }

    onServiceRequested(partner: Partner) {
      // @ts-ignore
      this.$refs[`partner-${partner.id}`][0].longPollRequest();
    }

    get sortedPartners() {
      return this.partners.sort((a, b) => {
        if (a.type === PartnerType.asbestosInspector) return -1;
        if (a.type === PartnerType.photo) return -1;

        return 0;
      });
    }
  }
